@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    max-width: 1440px;
    box-sizing: border-box;
    width: 100%;
    padding: 50px 20px;
}

.box_info{
    box-sizing: border-box;
}


.title{
    @include title;
    text-align: left;
}

.text{
    @include text;
    text-align:justify;
}

.image{
    width: 500px;
    aspect-ratio: 4/3;
    border-radius: 6px;
    float:left;
    margin: 0 20px 0 0;
}


@media(max-width: 1100px){
    .image{
        width: 45%;
    }
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;   
        padding: 40px 10px;
    }

    .image{
        width: 100%;
        margin: 0 0 20px;
    }
    
    .box_info{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;  
    }

}

@media(max-width: 425px){

    .container{
    padding: 30px 10px;
    }

    
    .button{
        width: 160px;
        height: 40px;
        font-size: 12px;
    }
}