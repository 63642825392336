@import '../../base.scss';

.button{
    width: 50px;
    height: 50px;
    background: $element;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 3000;
    border: none;

    &::before{
        box-sizing: border-box;
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        background-image: url(../../image/UpButton/top.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transform: rotate(180deg);
    }

    &:hover{
        opacity: .7;
        cursor: pointer;
        transition: all .3s ease;
    }
}


@media(max-width: 768px){
    .button {
        right: 15px;
        bottom: 15px;
        width: 40px;
        height: 40px;
    }
}