@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
}

.container{
    @include main-column;
    padding-bottom: 100px
}

.title{
    @include title;
    margin-bottom: 60px;
}

.list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    row-gap: 50px;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba( $black, .6 );
    padding: 20px 10px;
    position: relative;
    z-index: 1;
}

.box_figure{
    font-family:  "Roboto", "Arial ", sans-serif;
    position: absolute;
    font-size: 150px;
    line-height: 1;
    color: rgba(235, 235, 235, 0.1);
    right: 10px;
    top: -60px;
    margin: 0;
    opacity: .3;
}

.box_image{
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $element;
}


.icon{
    width: 50px;
    height: 50px;
}

.item_title{
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    text-align: center;
    color: $white;
    margin: 20px 0 0;
}

.item_text{
    color: $text-box;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    margin: 10px 0 0;
}


.button{
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    border: none;
    padding: 0;
    color: $white;
    max-width: 350px;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 8px;
    background: $element;
    box-shadow: 0 4px 0 #8b1617;
    transform: translateY(-5px);
    z-index: 1;
    overflow: hidden;

    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba($red, 0.25), 0px 0px 4px 4px rgba($red, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;
    }
}

.glare{
    @include glare
}

.image{
    width: 350px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 1%;
}


@media(max-width: 900px){
    button{
        max-width: 250px;
    }
    
}

@media(max-width: 768px){
    .container{
        padding-bottom: 100px
    }

    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .image{
        width: 250px;
    }
}