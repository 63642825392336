@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    width: 100%;
}

.container{
    @include main-column;
    padding-bottom: 30px
}

.title{
    @include title;
    margin-bottom: 30px;
}

.subtitle{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    color: $text-box;
    margin: 10px 0 20px;
    text-align: center;
}

.box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text{
    @include text;

    & span {
        font-weight: 700;
        color: $red;
        margin-right: 5px;
    }
}


@media(max-width: 425px){
    .subtitle{
        font-size: 16px;
    }
}