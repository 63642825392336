@import '../../base.scss';

.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 5;
    position: fixed;
    top: 0px; 
}

.header_scroll {
    background-color: $background-header;
    position: fixed;
    top: 0px;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 20px;
}

.info{
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.box_logo{
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}


.logo_link{
    box-sizing: content-box;
    display: flex;
    align-items: center;
    width: 250px;
}

.logo{
    width: 350px;
    height: auto;
}

.logo_min{
    display: none;
}

.contacts{
    max-width: 270px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list{
    max-width: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item{
    box-sizing: content-box;
}

.link_telegram{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(293.89deg, #0782BE 35.44%, #7BCEF8 94.34%);

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.link_whatsapp{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(293.89deg, #008F00 35.44%, #0AC143 94.34%);

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.icon_inst{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ed174b;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.image_icon{
    width: 20px;
    height: 20px;
}

.box_phone{
    max-width: 170px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.phone{
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    margin: 0 0 5px;
    text-decoration: none;
    color: $white;
    vertical-align: bottom;

    &:hover{
        color: $red;
        transition: .3s;
    }
}


.nav{
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.link{
    color: $white;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    text-decoration: none;
    position: relative;
    transition: all .3s ease-out;

    &:hover{
        cursor: pointer;
        transition: all .3s ease-out;
    }

    &::before{
        background: $element;
        position: absolute;
        content: '';
        top: 20px;
        bottom: 0;
        width: 100%;
        height: 2px;
        transition: all .3s ease-out;
        transform: scaleX(0);
    }
    &:hover:before{
        transform: scaleX(1);
    }
}

.button  {
    display: none;
}

@media(max-width: 1020px){
    .link{
        font-size: 16px;
        }
}

@media(max-width: 900px){
    .link{
        font-size: 14px;
        }
}

@media(max-width: 768px){

    .box{
        padding: 5px 10px;
        align-items: center;
    }

    .logo_link{
        width: 200px;
    }

    .logo{
        width: 200px;
        height: auto;
    }

    .box_logo{
        padding-bottom: 0;
    }

    .list{
        display: none;
    }

    .contacts{
        max-width: 150px;
        margin-left: 10px;
    }

    .box_phone{
        max-width: 150px;
    }

    .phone{
        font-size: 16px;
    }

    .nav{
        display: none;
    }


    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border: 0;
        background-image: url(../../image/Header/burger.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 20px;

        &:hover{
            cursor: pointer;
            filter: drop-shadow(0 0 5px $white);
            transition: all .3s ease-out;
        }
    }
}

@media(max-width: 450px){

    .logo{
        display: none;
}

.logo_link{
    width: 50px;
}

.logo_min{
    display: block;
    width: 70px;
    height: auto;
    }
}