@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../image/Portfolio/img.webp);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
    position: relative;
    padding: 0;
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
}

.box{
    @include main-column;
    background-color: rgba($background, .6);
}


.list{
    @include list
}

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $background;
    border-radius: 6px;
    overflow: hidden;
    transition: .5s;

    &:hover{
        cursor: pointer;
        transform:scale(1.02);
        transition: .5s;
    }
}

.box_info{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.item_image{
    width: 100%;
    aspect-ratio: 4/3; 
}

.item_title{
    font-size: 18px;
    line-height: 1.2;
    font-weight: 700;
    width: 100%;
    margin: 0 0 20px;
    color: $white;
    text-align: center;
}

.list_product{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 20px;

}

.button{
    width: 100%;
    height: 40px;
    border: 0;
    color: white;
    background-color: $red;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    border-radius: 6px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: 0 4px 0 #8b1617;
    transform: translateY(-5px);
    overflow: hidden;

    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba($red, 0.25), 0px 0px 4px 4px rgba($red, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;
    }
}

.glare{
    @include glare
}

.phone{
    display: none;
}


@media(max-width: 768px){
    .list{
        gap: 10px;
    }

    .button{
        display: none;
    }
    
    .phone{
        width: 100%;
        height: 40px;
        border: 0;
        color: white;
        background-color: $red;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        border-radius: 6px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    
        &:hover{
            cursor: pointer;
            background-color: $element;
            transform: scale( .95 );
            transition: all .5s ease-out;
        }
    }
}