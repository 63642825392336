@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    padding-bottom: 30px
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list{
    @include list;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0 10px 20px;
    position: relative;
    border-left: 2px solid hsla(0,0%,92%,.1);
}

.number{
    font-size: 60px;
    line-height: 1.2;
    font-weight: 700;
    color: hsla(0,0%,92%,.1);
    margin: 0;
}

.item_title{
    font-weight: 700;
    font-size: 20px;
    height: 48px;
    line-height: 1.2;
    text-align: left;
    color: $white;
    margin: 10px 0 0;
}

.item_text{
    color: $white;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
}
