@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

.box{
    @include main-column;
    padding: 0 20px 50px;
}


.list{
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    padding: 5px;
}

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $background;
    border-radius: 6px;
    overflow: hidden;
    transition: .5s;
    box-shadow: 0 0 15px rgba( $black, .9 );

    &:hover{
        cursor: pointer;
        transform:scale(1.02);
        transition: .5s;
    }
}

.box_info{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.item_image{
    width: 100%;
    aspect-ratio: 4/3; 
}

.item_title{
    font-size: 22px;
    line-height: 1.2;
    font-weight: 700;
    width: 100%;
    margin: 0 0 10px;
    color: $white;
    text-align: center;
}

.item_text{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    margin: 0 0 5px 0;
    color: $text-box;
}

.list_product{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 20px;

}

@media(max-width: 900px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}


@media(max-width: 768px){
    .box{
        padding: 0 10px 40px;
    }

    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 10px;
    }

    .item_title{
        font-size: 20px;
    }
    
    .item_text{
        font-size: 16px;
    }
}


@media(max-width: 425px){
    .box{
        padding: 0 10px 30px;
    }
}