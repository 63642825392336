@import '../../base.scss';


    .main {
        max-width: 1920px;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .overlay{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(1, 15, 23, 0);
    }

.container{
    box-sizing: border-box;
    max-width: 1400px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item {
    color: $white;
    background-image: url(../../image/InitialPage/img.webp);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 55%;
}

.title{
    max-width: 650px;
    font-size: 50px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0;
    position: relative;
    text-transform: uppercase;
}

.line{
    background: $element;
    height: 3px;
    width: 50px;
    margin: 30px 0 20px; 
}

.text{
    max-width: 400px;
    font-size: 22px;
    line-height: 1.4;
    font-weight: 500;
    text-align: left;
    margin: 0;
    color: rgba($white, .8)
}

.button{
    background-color: $element;
    text-transform: uppercase;
    color: $white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    max-width: 300px;
    width: 100%;
    height: 50px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 4px 0 #8b1617;
    transform: translateY(-5px);
    overflow: hidden;

    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba($red, 0.25), 0px 0px 4px 4px rgba($red, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;
    }
}

.glare{
    @include glare
}

@media(max-width: 930px){
    .title{
        max-width: 700px;
        font-size: 44px;
    }

    .text{
        font-size: 20px;
    }

}

@media(max-width: 768px){
    .main {
        justify-content: flex-start;
        align-items: center;
    }

    .overlay{
        justify-content: center;
        align-items: flex-start;
    }

    .container{
        margin-top: 70px;
    }


    .item {
        background-image: url(../../image/InitialPage/img_1.webp);
        background-position: bottom center;
        background-size: 70%;
    }

    .title{
        font-size: 38px;
        max-width: 500px;
    }

    .text{
        font-size: 20px;
        max-width: 350px;
    }

    .line{
        background: $element;
        height: 3px;
        width: 30px;
        margin: 20px 0 10px; 
    }
}

@media(max-width: 580px){

    .item {
        background-position: center;
        background-size: 85%;
    }

    .container{
        margin-top: 105px;
        height: 100%;
        align-items: center;
    }

    .title{
        max-width: 350px;
        font-size: 28px;
        text-align: center;
    }

    .text{
        font-size: 18px;
        text-align: center;
    }

    .button{
        width: 100%;
        height: 40px;
        margin: auto auto 200px;
    }
}

@media(max-width: 500px){
    .button{
        max-width: 90%;
    }

}

@media(max-width: 425px){
    .box{
        max-width: 100%;
    }

    .title{
        font-size: 27px;
        max-width: 320px;
    }
}

@media(max-width: 380px){
    .title{
        font-size: 24px;
        max-width: 300px;
    }
}